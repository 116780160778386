import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [calendarView, setCalendarView] = useState('WEEK');

  const isMobileDevice = () => {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  };

  useEffect(() => {
    if (isMobileDevice()) {
      setCalendarView('AGENDA');
    } else {
      setCalendarView('WEEK');
    }
  }, []);

  return (
    <div className="App">
      <div className="page-content">
        <iframe
          src={`https://calendar.google.com/calendar/embed?height=600&wkst=2&ctz=America%2FDenver&bgcolor=%23ffffff&showTitle=0&showPrint=0&showCalendars=0&mode=${calendarView}&src=anJzYWxpbmFzMjAwOUBnbWFpbC5jb20&src=aG5hdWdvdnNxcHJrNW92YWRzdHUycWM1cGdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=Y2hyaXNAdGhpc2NocmlzdG9ncmFwaHkuY29t&color=%23D50000&color=%233F51B5&color=%23039BE5`}
          title="Google Calendar"
          width="800"
          height="600"
          frameBorder="0"
          scrolling="no"
        ></iframe>
      </div>
    </div>
  );
}

export default App;
